import React, {type PropsWithChildren, useEffect, useState} from 'react';
import {useUser} from '@deezer/react-user';
import {getCDP} from '@modules/tracking';
import {type Trackables} from '@deezer/logcenter/build/types/Sender/GTMSender/types';

export type TrackingProviderProps = {tracking: Partial<Trackables>};

const TrackingProvider: React.FC<PropsWithChildren<TrackingProviderProps>> = ({
	children,
	tracking,
}) => {
	const [user] = useUser();
	const [isCdpReady, setIsCdpReady] = useState(false);

	useEffect(() => {
		if (isCdpReady && user?.id && user?.id !== '0' && tracking?.pagename) {
			getCDP().identify({userId: user?.id});
			getCDP().logEvent({
				eventName: 'screen_view',
				type: 'cdp',
				customAttributes: {
					screen_view_name: tracking.pagename,
				},
				uid: user.id,
			});
		}
	}, [user?.id, isCdpReady, tracking]);

	useEffect(() => {
		getCDP().init();
		setIsCdpReady(true);
	}, []);

	return <>{children}</>;
};

export default TrackingProvider;
