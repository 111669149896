import React, {useContext, useRef, createContext} from 'react';
import type {FC} from 'react';
import {AuthBase, QUERY_PARAMS_TYPE, WithArlLogin} from '@deezer/auth';
import {config} from '@modules/config';
import {useApiCall} from '@deezer/react-legacy-api';

const customFetch: typeof fetch = (url, init = {}) => {
	init.credentials = 'include';
	return globalThis.fetch(url, init);
};

const Auth = WithArlLogin(AuthBase);
const authInstance = new Auth({
	tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
	refreshTokenOutputType: QUERY_PARAMS_TYPE.COOKIE,
	host: config.get('host_authent'),
	customFetch,
});

type AuthProviderValue = {
	getToken: () => Promise<string>;
	invalidateCurrentToken: typeof authInstance.invalidateCurrentToken;
};

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({children}) => {
	const value = useProviderAuth();
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

declare global {
	interface Window {
		token: string;
	}
}

export default AuthProvider;
export const useAuth = (): AuthProviderValue => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

// Provider hook that creates auth object and handles state
function useProviderAuth(): AuthProviderValue {
	const apiCall = useApiCall();
	const getTokenPromiseRef = useRef<Promise<string> | null>(null);

	const fetchToken = async (arl?: string): Promise<string> => {
		return (arl ? authInstance.loginWithArl(arl) : authInstance.loginWithArl())
			.then((value) => {
				return value ?? '';
			})
			.catch((error: {name: string}) => {
				return error.name ?? '';
			});
	};

	const getToken = async (): Promise<string> => {
		if (getTokenPromiseRef.current) {
			return getTokenPromiseRef.current;
		}

		const tokenPromise = authInstance
			.getToken()
			.then((value) => {
				return value ?? '';
			})
			.catch(async () => {
				await apiCall({
					method: 'deezer_ping',
					data: {FORCE: true},
				});

				const res: any = await apiCall({
					method: 'deezer_userAutolog',
					data: {FORCE: true},
				});

				if (res.jwt) {
					return res.jwt;
				}

				return fetchToken(res?.arl);
			})
			.finally(() => {
				getTokenPromiseRef.current = null;
			});

		getTokenPromiseRef.current = tokenPromise;
		return tokenPromise;
	};

	const invalidateCurrentToken = authInstance.invalidateCurrentToken;

	return {getToken, invalidateCurrentToken};
}
