import React from 'react';

import {i18n} from 'next-i18next';

import {
	ColorModeScript,
	extendTheme,
	createLocalStorageManager,
	TempoProvider,
	useColorMode,
} from '@tempo/core';
import {useRouter} from 'next/router';

const STORAGE_KEY = 'webviews-color-mode';
const localStorageManager = createLocalStorageManager(STORAGE_KEY);

const getDirection = (locale: string): 'rtl' | 'ltr' => {
	return i18n?.dir(locale) || 'ltr';
};

const ThemeProvider: React.FC<{
	children?: React.ReactNode;
}> = ({children}) => {
	const {locale, isReady, query} = useRouter();
	const direction = getDirection(locale || 'en');

	React.useEffect(() => {
		document.querySelector('body')?.setAttribute('dir', direction);
	}, [direction]);

	if (!isReady) return null;

	const theme = extendTheme({
		config: {
			direction,
			initialColorMode: query.dark_mode !== 'true' ? 'light' : 'dark',
		},
		components: {
			// TODO: Need to be fixed in Tempo web instead
			Checkbox: {
				sizes: {
					md: {
						label: {
							fontSize: 'body.s',
							fontWeight: 'body.s',
							lineHeight: 'body.s',
							fontFamily: 'body.s',
						},
					},
				},
			},
		},
	});

	return (
		<>
			<ColorModeScript
				initialColorMode={theme.config.initialColorMode}
				storageKey={STORAGE_KEY}
			/>
			<TempoProvider colorModeManager={localStorageManager} theme={theme}>
				<ColorModeSync />
				{children}
			</TempoProvider>
		</>
	);
};

function ColorModeSync() {
	const {query} = useRouter();
	const {setColorMode, colorMode} = useColorMode();
	const requestedMode = query.dark_mode !== 'true' ? 'light' : 'dark';

	React.useEffect(() => {
		if (requestedMode !== colorMode) {
			setColorMode(requestedMode);
		}
	}, [requestedMode, colorMode, setColorMode]);
	return null;
}

export default ThemeProvider;
