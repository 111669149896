import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {config} from '@modules/config';

/**
 * We have 2 ways of handling auth in webviews:
 * - either the mobile app sets cookies directly
 * - (legacy) or they provide query params that we can set ourselves
 * - (deprecated) we used to take the qp and forward them to the fetch calls but it's useless if we can set cookies instead
 */
export const useSyncCookiesAndQuery = () => {
	const [synced, setSynced] = useState(false);
	useEffect(() => {
		const qp = new URLSearchParams(location.search);
		const arl = qp.get('arl');
		const accountId = qp.get('account_id');
		const sid = qp.get('sid');
		if (arl === 'true' && sid) {
			Cookies.set('arl', sid, {domain: config.get('host_cookie')});
		} else {
			if (arl) {
				Cookies.set('arl', arl, {domain: config.get('host_cookie')});
			}
			if (sid) {
				Cookies.set('sid', sid, {domain: config.get('host_cookie')});
			}
		}
		if (accountId) {
			Cookies.set('account_id', accountId, {domain: config.get('host_cookie')});
		}
		setSynced(true);
	}, []);
	return synced;
};
